import { Divider, IconButton, Modal, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomButton } from 'components/Button';
import Title from 'components/Typography/Title';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Wrapper } from '../../Fleet/Modals/styled.modals';
import { DialogTitle } from '../../components/Dialog';
import Loader from '../../components/Loader/Loader';
import Text from '../Typography/Text';
import EmployeeCard, { AssignCard } from './EmployeeCard';
import { CustomEmptyState } from './EmptyState';
import useAssignLogic from './useAssignLogic';
import AssignFilter from './AssignFilter';
import 'react-virtualized/styles.css';
// import { Column, Table } from 'react-virtualized';
// import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
// import List from 'react-virtualized/dist/commonjs/List';
import { List } from 'react-virtualized';
import { noop } from 'lodash';
import { useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export default function AssignDialog({
  open,
  toggleOpenModal: toggle,
  moduleName = '',
  selectedDepartment,
  setDepartmentFilter = () => {},
  setSearchFilter = () => {},
  title = 'Select People',
  desc,
  noMultipleSelect = false,
  isAssigning = false,
  handleSubmit,
  allowDepartment = true,
  submitText = 'Assign',
  allowSelectAll = true,
  CardComponent = AssignCard,
  showSelected,
  selectedRender = noop,
  defaultSelectedInfo,
  ...rest
}) {
  const {
    errMsg,
    assignMutation,
    handleSelectAll,
    handleSelect,
    depData,
    isDepartmentLoading,
    searchRef,
    debouceSearch,
    selected,
    isAllSelected,
    setSelected,
    module,
    searchFilter
  } = useAssignLogic(rest);

  const employeeList = (
    <List
      // style={{ height: '400px' }}
      // totalCount={module.data?.length}
      width={500}
      height={400}
      rowCount={module.data?.length}
      rowHeight={80}
      rowRenderer={({ index, key, style }) => {
        const item = module?.data?.[index];
        const isSelected = selected.some((el) => el === item?.id);
        const checkboxHandler = (e) => {
          e.stopPropagation();
          if (!noMultipleSelect) {
            handleSelect(e, item?.id);
          } else {
            setSelected([item.id]);
          }
        };
        return (
          <EmployeeCard
            style={style}
            key={key}
            details={item}
            isSelected={isSelected}
            handleSelect={checkboxHandler}
            noMultipleSelect={noMultipleSelect}
            // key={item?.id + item?.first_name + index}
            noChecks={noMultipleSelect}
            CardComponent={CardComponent}
          />
        );
      }}
    />
  );

  // console.log(
  //   { searchFilter, emp: module.data, selected, defaultSelectedInfo },
  //   'datas'
  // );

  const selectedView = useMemo(() => {
    return selected
      ?.map((employee) => module?.data?.find((item) => item.id === employee))
      ?.map((employee) => (
        <Stack
          direction='row'
          key={employee.id}
          alignItems={'center'}
          gap={'1rem'}
          sx={{
            background: '#EBEFFA',
            paddingInline: '.5rem 5px',
            borderRadius: 20
          }}
        >
          {employee?.first_name + ' ' + employee?.last_name}
          <IconButton
            title='remove'
            size='medium'
            onClick={() => handleSelect({}, employee?.id)}
          >
            <CloseIcon sx={{ fontSize: 14, color: '#4069D0' }} />
          </IconButton>
        </Stack>
      ));
    // eslint-disable-next-line
  }, [selected]);

  return (
    <Modal
      fullWidth={false}
      maxWidth={'xs'}
      onClose={(e) => toggle(selected)}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Wrapper
        width='550px'
        widthPercent='70%'
        padding='20px'
        // style={{ position: 'relative' }}
      >
        <AssignModalSC>
          <DialogTitle
            iconStyle={{
              top: 10
            }}
            id='customized-dialog-title'
            sx={{
              py: 1
            }}
            onClose={() => toggle(selected)}
          >
            <Title order={4} fw={700}>
              {title}
            </Title>
          </DialogTitle>
          <Divider
            sx={{
              marginBottom: '.7rem',
              marginInline: '1rem'
            }}
          />
          <div className='content'>
            <Text size='sm' my={1} variant='body1'>
              {desc
                ? desc
                : `Select People who you want to assign this ${moduleName} to.`}
            </Text>

            <AssignFilter
              {...{
                depData,
                isDepartmentLoading,
                searchRef,
                debouceSearch,
                setDepartmentFilter,
                allowDepartment,
                selectedDepartment,
                setSearchFilter
              }}
            />
            {showSelected && selected?.length > 0 ? (
              <Stack
                direction={'row'}
                sx={{ overflowX: 'auto', gap: '.5rem', my: '1rem' }}
              >
                {selectedView}
              </Stack>
            ) : null}

            {noMultipleSelect || !allowSelectAll ? null : (
              <Typography
                mt={1.5}
                fontWeight={600}
                color='#2898a4'
                sx={{
                  cursor: 'pointer'
                }}
                onClick={handleSelectAll}
              >
                {module?.data?.length > 0
                  ? isAllSelected
                    ? 'Deselect all'
                    : 'Select all'
                  : null}
              </Typography>
            )}
            {!module ? (
              <CustomEmptyState title={'No employees found'} />
            ) : module?.isLoading ? (
              <Loader />
            ) : module?.data?.length < 1 ? (
              <CustomEmptyState
                title={errMsg || 'All employees have been Assigned'}
              />
            ) : (
              // <Box
              //   my='1rem'
              //   className='employees_container'
              //   sx={{
              //     maxHeight: '320px',
              //     overflowY: 'auto'
              //   }}
              // >
              <div className='employees'>{employeeList}</div>
              // </Box>
            )}
          </div>
          <div>
            {module?.data?.length > 0 && (
              <CustomButton
                bold
                variant={'contained'}
                style={{ width: '100%' }}
                fullWidth
                disabled={selected.length < 1}
                onClick={() => {
                  if (assignMutation !== undefined) {
                    return assignMutation
                      ?.mutateAsync(selected)
                      ?.then((_) => {
                        setSelected([]);
                      })
                      ?.catch((err) => console.error(err));
                  } else if (handleSubmit) {
                    return handleSubmit(selected, () => toggle(selected));
                  }

                  toggle(selected);
                }}
              >
                {assignMutation?.isLoading || isAssigning ? (
                  <CircularProgress size={20} sx={{ color: '#ffffff' }} />
                ) : (
                  submitText
                )}
              </CustomButton>
            )}
          </div>
        </AssignModalSC>
      </Wrapper>
    </Modal>
  );
}
const AssignModalSC = styled.div`
  .content {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
  }

  .employees_container {
    list-style: none;
  }

  .employees_container::-webkit-scrollbar {
    width: 1em;
  }

  .employees_container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .employees_container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  /* .btn_assign {
    background: #2898a4;
    color: #ffffff;
    font-weight: 600;
    &:hover {
      background-color: #2898a4;
    }
  } */
`;

AssignDialog.defaultProps = {
  open: false,
  toggleOpenModal: () => {},
  //  useModule : ()=> {},
  // useModuleAssign : ()=> {},
  invalidateModule: () => {},
  moduleName: '',
  onAssignSuccess: () => {},
  onAssignError: () => {}
};

AssignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpenModal: PropTypes.func.isRequired,
  useModuleAssign: PropTypes.func,
  invalidateModule: PropTypes.func,
  onAssignError: PropTypes.func,
  onAssignSuccess: PropTypes.func
};
